/**
 * 微力指数计算函数
 */
import { getPointNum, toPercent } from "../../api/httpApi";
import _ from "lodash";
/************   私有函数   ************* */
const getMFromXType = (xType) => {
  if (xType == 1) {
    return 5 / 7;
  } else if (xType == 3) {
    return 3 / 30.5;
  }
  return 1 / 7;
};
// 阅读率:人次、总数
const _procLv = (gzNum, perNum, totalNum, maxScore) => {
  let ret = 0;
  if (totalNum) {
    ret = perNum / (gzNum * totalNum) || 0;
  }
  return {
    rate: toPercent(ret),
    score: getPointNum(maxScore * ret),
  };
};
/************   私有函数   ************* */
// 处理到达率数据
const _proc_followRate = (iterator) => {
  if (iterator["单位"]) {
    const followRate = iterator["已激活"] / iterator["总人数"];
    // 到达得分=5×到达率×M。（最高5分，最低0分，M=1或0）
    // 注：各单位到达率在95%及以上M为1，否则为0。
    return {
      id: iterator["单位"],
      deptId: iterator["deptId"],
      单位: iterator["单位"],
      总人数: iterator["总人数"],
      关注人数: iterator["已激活"],
      到达率: followRate,

      followRate: toPercent(followRate, 0),
      followScore: getPointNum(5 * followRate * (followRate >= 0.95 ? 1 : 0)), // 到达率得分
    };
  }
  return null;
};
// 处理站段推文得分
// sendContentDay: 7
// zdContentCanShareNum: 0
// zdPubContentNum: 10
// zdYuanchangContentNum: 10
const _proc_zdContent = (deptInfo, zdData, alldays) => {
  const retData = {
    yuanchuangRate: 0, // 原创率
    yuanchuangScore: 0, // 原创率得分

    pubContentRate: -1, //推文效率 最小 -1
    pubContentScore: 0, //推文得分
  };
  if (zdData) {
    //  原创率=（原创文章数/推文总数）×100% 原创得分=5×原创率（最高5分）
    if (zdData.zdPubContentNum) {
      const yuanchuangRate =
        zdData.zdYuanchangContentNum / zdData.zdPubContentNum || 0;
      retData["yuanchuangRate"] = toPercent(yuanchuangRate);
      retData["yuanchuangScore"] = getPointNum(yuanchuangRate * 5);
    }
    // 2. 推文效率=（D/91.5-X）/（D/91.5+X）×0.5+(N/91.5-X)/（N/91.5+X）×0.5
    // 推文效率=（推送天数/总天数 - X) / （推送天数/总天数 + X) * 0.5 +
    // （原创推文数 / 总天数 - X) /  （原创推文数 / 总天数 + X) * 0.5

    // 推文得分=5×M+7.85×(推送效率+1)。（最高∞20分，“7.85”为分值调节系数，“1”为正值调节系数）
    const DOveralldays = zdData.sendContentDay / alldays || 0;
    const M = zdData.zdPubContentNum > 0 ? 1 : 0;

    const X = getMFromXType(deptInfo.xType);
    const yuanchuangOveralldays = zdData.zdYuanchangContentNum / alldays || 0;
    const pubContentRate =
      ((DOveralldays - X) / (DOveralldays + X)) * 0.5 +
      ((yuanchuangOveralldays - X) / (yuanchuangOveralldays + X)) * 0.5;

    retData["pubContentRate"] = pubContentRate.toFixed(2);
    retData["pubContentScore"] = getPointNum(
      5 * M + 7.85 * (pubContentRate + 1)
    );
    // console.log(
    //   "countRate----A B C",
    //   (DOveralldays - X) / (DOveralldays + X),
    //   (yuanchuangOveralldays - X) / (yuanchuangOveralldays + X),
    //   pubContentRate
    // );
  }

  return retData;
};
/**
 // 处理站段阅读得分
// ljContentCanShareNum: 203 // 路局可分享的发文数
// ljContentNum: 204 // 路局文章总数（重磅+热点）
// ljHotNum: 35 // 路局热点发文数
// ljZhongBangNum: 169 // 路局重磅发文数
//  perComment: 346,           跟评重点推文（“重磅”“热点”）链接的总人次
//  perLike: 4039,             点赞重点推文（“重磅”“热点”）链接的总人次
//  perShare: 250,             单位转发分享“重点推文”(“重磅”“热点”)总人次
//  perHotCount: 63,           热点文章阅读人次
//  perInZdContentCount         单位推文(内部人员)总阅读人次
//  perZhongbangCount: 9193,   重磅文章阅读人次
 */
const _proc_zdRead = (deptInfo, zdData, ljData) => {
  const retData = {
    countRate: -0.5, // 阅读效率最低  -0.5
    countScore: 0, // 阅读得分

    shareRate: 0, // 转发率
    shareScore: 0, // 转发得分

    commentRate: 0, // 跟评率
    commentScore: 0, // 跟评得分

    likeRate: 0, // 点赞率
    likeScore: 0, // 点赞得分

    commentViewRate: 0, // 优质评论率
    commentViewScore: 0, // 优质评论得分
  };
  if (zdData) {
    const gzNum = deptInfo["关注人数"];
    if (gzNum) {
      //  3. 阅读效率
      let countRate = -0.5;
      let A = -0.7; // “重磅”推文阅读效率A
      let B = -0.3; // “热点”推文阅读效率B
      let C = -0.3; // 单位推文阅读效率C
      //  重磅”链接阅读效率A=（单位阅读“重磅”链接总人数-单位人数×70% ×N₁）/（单位人数×N₁），N₁为集团企业号“重磅”链接数，A最高为0.3、最低为－0.7。
      const _N1 = ljData.ljZhongBangNum;
      if (_N1) {
        A = (zdData.perZhongbangCount - gzNum * 0.7 * _N1) / (gzNum * _N1);
      }
      // “热点”链接阅读效率B=（单位阅读“热点”链接总人数-单位人数×30% ×N₂）/（单位人数×N₂），N₂为集团企业号“热点”链接数，B最高为0.7、最低为－0.3。
      const _N2 = ljData.ljHotNum;
      if (_N2) {
        B = (zdData.perHotCount - gzNum * 0.3 * _N2) / (gzNum * _N2);
      }
      //  单位推文阅读效率C=（单位企业号推文总阅读量-单位人数×30% ×c）/（单位人数×c），c为本单位企业号推文总数，C最高为0.7、最低为－0.3。
      const _c = zdData.zdPubContentNum;
      if (_c) {
        C = (zdData.perInZdContentCount - gzNum * 0.3 * _c) / (gzNum * _c);
      }
      // 阅读效率=A×0.5+B×0.3+C×0.2 (效率最高为0.5、最低为－0.5）
      countRate = A * 0.5 + B * 0.3 + C * 0.2;
      // 阅读得分=30×（阅读效率+0.5）。（最高30分，“0.5”为正值调节系数）
      retData["countRate"] = countRate.toFixed(2);
      retData["countScore"] = getPointNum(30 * (countRate + 0.5));

      // 4. 转发率
      // 转发率=单位转发分享“重点推文”总人数/（单位人数×可被转发分享的“重点推文”数）×100%
      // 转发得分=5×转发率。（最高5分）
      // 注：只针对有转发权限的链接核算。
      // 可被转发分享的“重磅”“热点”“ = ljContentCanShareNum
      const share_lv = _procLv(
        gzNum,
        zdData.perShare,
        ljData.ljContentCanShareNum,
        5
      );
      retData["shareRate"] = share_lv.rate;
      retData["shareScore"] = share_lv.score;
      // 5. 跟评率
      // 跟评率=单位跟评“重点推文”的总人数/（单位人数×“重点推文”数）×100%
      // 跟评得分=10×跟评率。（最高10分）
      const comment_lv = _procLv(
        gzNum,
        zdData.perComment,
        ljData.ljContentNum,
        10
      );
      retData["commentRate"] = comment_lv.rate;
      retData["commentScore"] = comment_lv.score;
      // 6. 点赞率
      // 点赞率=单位点赞“重点推文”的总人数/（单位人数×“重点推文”数）
      // 点赞得分=5×点赞率。（最高5分）
      const like_lv = _procLv(gzNum, zdData.perLike, ljData.ljContentNum, 5);
      retData["likeRate"] = like_lv.rate;
      retData["likeScore"] = like_lv.score;
    }
    // 7. 优质跟评
    // 优质跟评率=（单位优质跟评数/单位跟评总数）×100％
    // 注：推文跟评经编辑审核并择优显示的跟评即为优质跟评。
    // 优质跟评得分=20×优质跟评率。（最高20分）
    if (zdData["comment"]) {
      const commentViewRate = zdData["commentView"] / zdData["comment"] || 0;
      retData["commentViewRate"] = toPercent(commentViewRate);
      retData["commentViewScore"] = getPointNum(commentViewRate);
    }
  }
  return retData;
};
/************   对外接口   ************* */
// allDeptInfo
// zdTjData 格式为：{ zdContentObjs, ljContentData }
export const procWeili = async (allDeptInfo, zdTjData, alldays) => {
  const ljContentData = zdTjData.ljContentData; // 路局发文
  const zdContentObjs = zdTjData.zdContentObjs; // 站段发文、阅读数
  // 遍历全部部门
  const retArr = [];
  for (const item of allDeptInfo) {
    // 计算完备率、关注人数等
    const deptInfo = _proc_followRate(item);
    if (deptInfo) {
      const zdData = zdContentObjs[item.deptId];
      // 处理站段推文得分
      const zdContentData = _proc_zdContent(deptInfo, zdData, alldays);
      // 处理站段阅读得分
      const zdReadData = _proc_zdRead(deptInfo, zdData, ljContentData);
      // 计算总分
      const weiliScore =
        deptInfo["followScore"] +
        zdContentData["yuanchuangScore"] +
        zdContentData["pubContentScore"] +
        zdReadData["countScore"] +
        zdReadData["likeScore"] +
        zdReadData["shareScore"] +
        zdReadData["commentScore"] +
        zdReadData["commentViewScore"];

      // 合并数据
      retArr.push(
        Object.assign(
          {
            weiliScore: getPointNum(weiliScore),
          },
          deptInfo,
          zdContentData,
          zdReadData
        )
      );
    }
  }
  // 排序
  // return _.reverse(_.sortBy(retArr, ["followRate", "总人数"]));
  return _.reverse(_.sortBy(retArr, ["weiliScore"]));
};
